import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Tarjima resurslari
const resources = {
        en: {
            translation: {
                "Fanlar": "Fanlar",
                "Biz haqimizda": "Biz haqimizda",
                "TestNest bilan bilimlaringizni sinang va o'sishni kuzatib boring": "TestNest bilan bilimlaringizni sinang va o'sishni kuzatib boring",
                "Ta'limni yaxshilang va imtihonlaringizni muvaffaqiyat bilan o'tkazing.": "Ta'limni yaxshilang va imtihonlaringizni muvaffaqiyat bilan o'tkazing.",
                "Google Play-da olish": "Google Play-da olish",
                "App Store-da yuklab olish": "App Store-da yuklab olish",
                "TestNest-ni nega tanlash kerak?": "TestNest-ni nega tanlash kerak?",
                "Keng qamrovli testlar": "Keng qamrovli testlar",
                "Turli fanlar va sinflar uchun testlar, sizning o'quv rejangizga mos ravishda.": "Turli fanlar va sinflar uchun testlar, sizning o'quv rejangizga mos ravishda.",
                "Tahliliy va o'ziga xos hisobotlar yordamida o'z muvaffaqiyatlaringizni kuzatib boring.": "Tahliliy va o'ziga xos hisobotlar yordamida o'z muvaffaqiyatlaringizni kuzatib boring.",
                "Ishlashni kuzatish": "Ishlashni kuzatish",
                "Shaxsiylashtirilgan ta'lim": "Shaxsiylashtirilgan ta'lim",
                "Qamrab olingan fanlar": "Qamrab olingan fanlar",
                "Ingliz tili": "Ingliz tili",
                "Tarix": "Tarix",
                "Geografiya": "Geografiya",
                "Fizika": "Fizika",
                "Kimyo": "Kimyo",
                "Biologiya": "Biologiya",
                "Matematika": "Matematika",
                "Fan": "Fan",
                "Ona tili": "Ona tili",
                "Adabiyot": "Adabiyot",
                "Huquq": "Huquq",
                "Rus tili": "Rus tili",
                "Test natijalariga asoslangan tavsiyalar va o'quv rejalari oling.": "Test natijalariga asoslangan tavsiyalar va o'quv rejalari oling.",
                "Biz TestNest-da sifatli ta'limni har kim uchun hamma joyda ochiq qilishga intilamiz.": "Biz TestNest-da sifatli ta'limni har kim uchun hamma joyda ochiq qilishga intilamiz.",
        }
    },
    uz: {
        translation: {
            "Fanlar": "Fanlar",
            "Biz haqimizda": "Biz haqimizda",
            "TestNest bilan bilimlaringizni sinang va o'sishni kuzatib boring": "TestNest bilan bilimlaringizni sinang va o'sishni kuzatib boring",
            "Ta'limni yaxshilang va imtihonlaringizni muvaffaqiyat bilan o'tkazing.": "Ta'limni yaxshilang va imtihonlaringizni muvaffaqiyat bilan o'tkazing.",
            "Google Play-da olish": "Google Play-da olish",
            "App Store-da yuklab olish": "App Store-da yuklab olish",
            "TestNest-ni nega tanlash kerak?": "TestNest-ni nega tanlash kerak?",
            "Keng qamrovli testlar": "Keng qamrovli testlar",
            "Turli fanlar va sinflar uchun testlar, sizning o'quv rejangizga mos ravishda.": "Turli fanlar va sinflar uchun testlar, sizning o'quv rejangizga mos ravishda.",
            "Tahliliy va o'ziga xos hisobotlar yordamida o'z muvaffaqiyatlaringizni kuzatib boring.": "Tahliliy va o'ziga xos hisobotlar yordamida o'z muvaffaqiyatlaringizni kuzatib boring.",
            "Ishlashni kuzatish": "Ishlashni kuzatish",
            "Shaxsiylashtirilgan ta'lim": "Shaxsiylashtirilgan ta'lim",
            "Qamrab olingan fanlar": "Qamrab olingan fanlar",
            "Ingliz tili": "Ingliz tili",
            "Tarix": "Tarix",
            "Geografiya": "Geografiya",
            "Fizika": "Fizika",
            "Kimyo": "Kimyo",
            "Biologiya": "Biologiya",
            "Matematika": "Matematika",
            "Fan": "Fan",
            "Ona tili": "Ona tili",
            "Adabiyot": "Adabiyot",
            "Huquq": "Huquq",
            "Rus tili": "Rus tili",
            "Test natijalariga asoslangan tavsiyalar va o'quv rejalari oling.": "Test natijalariga asoslangan tavsiyalar va o'quv rejalari oling.",
            "Biz TestNest-da sifatli ta'limni har kim uchun hamma joyda ochiq qilishga intilamiz.": "Biz TestNest-da sifatli ta'limni har kim uchun hamma joyda ochiq qilishga intilamiz.",
        }
    }
}
;

// i18next ni sozlash
i18n
    .use(LanguageDetector) // Foydalanuvchi tilini aniqlash
    .use(initReactI18next) // React-i18next ga i18n ni uzatadi
    .init({
        resources,
        fallbackLng: 'uz', // Default til
        debug: true,
        interpolation: {
            escapeValue: false, // React avtomatik ravishda qiymatlarni qochiradi
        }
    });

export default i18n;
