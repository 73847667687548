import {
    FaGooglePlay,
    FaApple,
    FaBook,
    FaChartLine,
    FaUserGraduate,
    FaFlagUsa,
    FaBookOpen,
    FaFontAwesomeFlag
} from 'react-icons/fa';
import {TiImageOutline} from 'react-icons/ti';
import {motion} from 'framer-motion';
import {FaBookAtlas} from "react-icons/fa6";
import {SiMoleculer} from "react-icons/si";
import {FaInfo} from "react-icons/fa6";
import {IoIosPaper} from "react-icons/io";
import {GoLaw} from "react-icons/go";
import {useTranslation} from 'react-i18next'; // Import useTranslation hook

const fadeInUp = {
    initial: {opacity: 0, y: 20},
    animate: {opacity: 1, y: 0},
    transition: {duration: 0.6}
};

const staggerChildren = {
    animate: {
        transition: {
            staggerChildren: 0.1
        }
    }
};

const teamMembers = [
    {name: "Xurshidbek O'ktamaliyev", role: 'Co-founder & CBO', image: '/placeholder.svg?height=200&width=200'},
    {name: 'Azizbek Sobitov', role: 'Co-founder & CEO', image: '/placeholder.svg?height=200&width=200'},
    {name: 'Alice Johnson', role: 'Co-founder & CTO', image: '/placeholder.svg?height=200&width=200'},
];

export default function App() {
    const {t} = useTranslation(); // Use the translation hook
    // useEffect(() => {
    //     const storedLanguage = localStorage.getItem('language');
    //     if (storedLanguage) {
    //         i18n.changeLanguage(storedLanguage); // Set the language from local storage
    //     }
    // }, [i18n]);
    //
    // const changeLanguage = (e) => {
    //     const selectedLanguage = e.target.value;
    //     i18n.changeLanguage(selectedLanguage); // Change language dynamically
    //     localStorage.setItem('language', selectedLanguage); // Save the selected language to local storage
    // };


    return (
        <div className="App bg-gradient-to-b from-blue-50 to-white min-h-screen">
            <motion.header
                className="bg-white shadow-md"
                initial={{opacity: 0, y: -50}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
            >
                <div className="container mx-auto px-4 py-6 flex justify-between items-center">
                    <motion.h1
                        className="text-3xl font-bold text-blue-600"
                        whileHover={{scale: 1.05}}
                        transition={{type: "spring", stiffness: 300}}
                    >
                        TestNest
                    </motion.h1>
                    <nav>
                        <motion.ul
                            className="flex space-x-8"
                            variants={staggerChildren}
                            initial="initial"
                            animate="animate"
                        >
                            {[
                                {label: t('Fanlar'), icon: FaBook},
                                {label: t('Biz haqimizda'), icon: FaInfo},
                            ].map((item, index) => (
                                <motion.li key={index} variants={fadeInUp}>
                                    <a href={`#${item.label.toLowerCase().replace(' ', '-')}`}
                                       className="text-gray-600 hover:text-blue-600 transition duration-300 flex items-center">
                                        <item.icon className="mr-2"/>
                                        {item.label}
                                    </a>
                                </motion.li>
                            ))}
                            {/*<motion.li variants={fadeInUp} className="flex items-center space-x-2">*/}
                            {/*    <FaLanguage className="text-gray-600"/>*/}
                            {/*    /!*<select onChange={changeLanguage}*!/*/}
                            {/*    /!*        className="bg-white border-none text-gray-600 hover:text-blue-600 transition duration-300">*!/*/}
                            {/*    /!*    <option value="uz">O'zbek</option>*!/*/}
                            {/*    /!*    <option value="en">English</option>*!/*/}
                            {/*    /!*</select>*!/*/}
                            {/*</motion.li>*/}
                        </motion.ul>
                    </nav>
                </div>
            </motion.header>

            <main>
                <section className="py-20 px-4">
                    <div className="container mx-auto text-center">
                        <motion.h2
                            className="text-5xl font-bold mb-6 text-gray-800"
                            initial={{opacity: 0, y: -20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.8}}
                        >
                            {t('TestNest bilan bilimlaringizni sinang va o\'sishni kuzatib boring')}
                        </motion.h2>
                        <motion.p
                            className="text-xl mb-12 text-gray-600 max-w-2xl mx-auto"
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{duration: 0.8, delay: 0.2}}
                        >
                            {t("Ta'limni yaxshilang va imtihonlaringizni muvaffaqiyat bilan o'tkazing.")}
                        </motion.p>
                        <motion.div
                            className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4"
                            variants={staggerChildren}
                            initial="initial"
                            animate="animate"
                        >
                            {[
                                {icon: FaGooglePlay, text: t("Google Play-da olish")},
                                {icon: FaApple, text: t("App Store-da yuklab olish")}
                            ].map((button, index) => (
                                <motion.a
                                    key={index}
                                    href="#"
                                    className="bg-black text-white px-8 py-4 rounded-full flex items-center justify-center hover:bg-gray-800 transition duration-300"
                                    variants={fadeInUp}
                                    whileHover={{scale: 1.05}}
                                    whileTap={{scale: 0.95}}
                                >
                                    <button.icon className="mr-2"/>
                                    {button.text}
                                </motion.a>
                            ))}
                        </motion.div>
                    </div>
                </section>

                <section id="features" className="py-20 px-4 bg-white">
                    <div className="container mx-auto">
                        <motion.h2
                            className="text-4xl font-bold text-center mb-16 text-gray-800"
                            variants={fadeInUp}
                            initial="initial"
                            whileInView="animate"
                            viewport={{once: true}}
                        >
                            {t('TestNest-ni nega tanlash kerak?')}
                        </motion.h2>
                        <motion.div
                            className="grid grid-cols-1 md:grid-cols-3 gap-12"
                            variants={staggerChildren}
                            initial="initial"
                            whileInView="animate"
                            viewport={{once: true}}
                        >
                            {[
                                {
                                    icon: FaBook,
                                    title: t("Keng qamrovli testlar"),
                                    description: t("Turli fanlar va sinflar uchun testlar, sizning o'quv rejangizga mos ravishda.")
                                },
                                {
                                    icon: FaChartLine,
                                    title: t("Ishlashni kuzatish"),
                                    description: t("Tahliliy va o'ziga xos hisobotlar yordamida o'z muvaffaqiyatlaringizni kuzatib boring.")
                                },
                                {
                                    icon: FaUserGraduate,
                                    title: t("Shaxsiylashtirilgan ta'lim"),
                                    description: t("Test natijalariga asoslangan tavsiyalar va o'quv rejalari oling.")
                                }
                            ].map((feature, index) => (
                                <motion.div
                                    key={index}
                                    className="text-center p-6 bg-blue-50 rounded-xl shadow-lg hover:shadow-xl transition duration-300"
                                    variants={fadeInUp}
                                    whileHover={{scale: 1.05}}
                                >
                                    <feature.icon className="text-5xl text-blue-600 mb-4 mx-auto"/>
                                    <h3 className="text-2xl font-semibold mb-4 text-gray-800">{feature.title}</h3>
                                    <p className="text-gray-600">{feature.description}</p>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </section>

                <section id="subjects" className="py-20 px-4">
                    <div className="container mx-auto">
                        <motion.h2
                            className="text-4xl font-bold text-center mb-16 text-gray-800"
                            variants={fadeInUp}
                            initial="initial"
                            whileInView="animate"
                            viewport={{once: true}}
                        >
                            {t('Qamrab olingan fanlar')}
                        </motion.h2>
                        <motion.div
                            className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6"
                            variants={staggerChildren}
                            initial="initial"
                            whileInView="animate"
                            viewport={{once: true}}
                        >
                            {[
                                {name: t('Ingliz tili'), icon: FaFlagUsa},
                                {name: t('Tarix'), icon: FaBookAtlas},
                                {name: t('Geografiya'), icon: TiImageOutline},
                                {name: t('Ona tili'), icon: FaBookOpen},
                                {name: t('Adabiyot'), icon: IoIosPaper},
                                {name: t('Huquq'), icon: GoLaw},
                                {name: t('Biologiya'), icon: SiMoleculer},
                                {name: t('Rus tili'), icon: FaFontAwesomeFlag}
                            ].map((subject, index) => (
                                <motion.div
                                    key={index}
                                    className="p-6 bg-blue-50 rounded-xl shadow-lg hover:shadow-xl transition duration-300 text-center"
                                    variants={fadeInUp}
                                >
                                    <subject.icon className="text-4xl text-blue-600 mb-4 mx-auto"/>
                                    <h3 className="text-lg font-semibold mb-2 text-gray-800">{subject.name}</h3>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </section>

                <section id="team" className="py-20 px-4 bg-white">
                    <div className="container mx-auto">
                        <motion.h2
                            className="text-4xl font-bold text-center mb-16 text-gray-800"
                            variants={fadeInUp}
                            initial="initial"
                            whileInView="animate"
                            viewport={{once: true}}
                        >
                            {t('Bizning jamoamiz')}
                        </motion.h2>
                        <motion.div
                            className="grid grid-cols-1 md:grid-cols-3 gap-12"
                            variants={staggerChildren}
                            initial="initial"
                            whileInView="animate"
                            viewport={{once: true}}
                        >
                            {teamMembers.map((member, index) => (
                                <motion.div
                                    key={index}
                                    className="p-6 bg-blue-50 rounded-xl shadow-lg hover:shadow-xl transition duration-300 text-center"
                                    variants={fadeInUp}
                                >
                                    <img src={member.image} alt={member.name}
                                         className="w-32 h-32 rounded-full mx-auto mb-4"/>
                                    <h3 className="text-lg font-semibold text-gray-800">{member.name}</h3>
                                    <p className="text-gray-600">{member.role}</p>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                </section>
            </main>

            <footer className="bg-gray-800 text-white py-4">
                <div className="container mx-auto text-center">
                    <p>{t('Copyright © 2024 TestNest. Barcha huquqlar himoyalangan.')}</p>
                </div>
            </footer>
        </div>
    );
}
